









































































import { Component, Vue } from 'vue-property-decorator'
import { phone } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import { AccountInfo } from '@/types/account'
@Component({
  name: 'UserList'
})
export default class extends Vue {
  private tableData: AccountInfo[] = []
  private loading = false
  private total = 0
  private page = 1
  private size = 10
  private showAddDialog = false
  private dialogTitle = '新增账户'
  private bindShow = false

  private formData = {
    name: '',
    mobile: '',
    loginId: ''
  }

  private rules = {
    name: [
      { required: true, message: '请输入姓名', trigger: ['blur', 'change'] }
    ],
    mobile: [
      { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
      { validator: phone, trigger: ['blur', 'change'] }
    ]
  }

  created () {
    this.getTableList()
  }

  initPage () {
    this.page = 1
    this.getTableList()
  }

  getTableList () {
    this.loading = true
    this.$axios
      .get<{ total: number; list: AccountInfo[] }>(
        this.$apis.account.selectAdminUserByPage,
        {
          page: this.page,
          size: this.size
        }
      )
      .then((res) => {
        if (res) {
          this.tableData = res.list || []
          this.total = res.total || 0
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  // 新增
  addAccount () {
    this.dialogTitle = '新增账户'
    this.showAddDialog = true
  }

  //  绑定新增账户
  bindAccount () {
    const url =
      this.dialogTitle === '新增账户'
        ? this.$apis.account.insertAdminUser
        : this.$apis.account.updateAdminUser
    ;(this.$refs.formData as ElForm).validate((valid) => {
      if (valid) {
        this.bindShow = true
        this.$axios
          .post(url, {
            ...this.formData
          })
          .then(() => {
            this.$message.success('绑定成功')
            this.showAddDialog = false
            this.initPage()
          }).finally(() => {
            this.bindShow = false
          })
      }
    })
  }

  // 取消新增
  cancelAdd () {
    this.showAddDialog = false
    this.clearDialogInfo()
  }

  // 重置密码
  resetPwd (userId: string) {
    this.$confirm('确定要重置密码？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios
          .post(this.$apis.account.resetPassword, {
            userId
          })
          .then(() => {
            this.$message.success('密码重置成功')
            this.initPage()
          })
      })
      .catch(() => {
        this.$message({
          type: 'info',
          message: '取消重置密码'
        })
      })
  }

  // 编辑
  editAccount (userId: string) {
    this.dialogTitle = '编辑账户'

    this.$axios
      .get(this.$apis.account.selectAdminUserByUserId, {
        userId
      })
      .then((res) => {
        this.showAddDialog = true
        this.$nextTick(() => {
          this.formData = res || {}
        })
      })
  }

  // 删除
  deleteAccount (userId: string) {
    this.$confirm('确定要删除此账户？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios
          .post(this.$apis.account.deleteAdminUser, {
            userId
          })
          .then(() => {
            this.$message.success('删除成功')
            this.initPage()
          })
      })
      .catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        })
      })
  }

  // 清空新增的输入框
  clearDialogInfo () {
    this.$nextTick(() => {
      ;(this.$refs.formData as ElForm).resetFields()
    // ;(this.$refs.formData as ElForm).clearValidate()
    })
  }

  mobileChange (value: string) {
    if (this.dialogTitle === '新增账户') {
      this.formData.loginId = value
    }
  }
}
